'use client'

import { useEffect, useState } from 'react'
import { useCartStore } from '../stores/orderStore';
import { useDialogStore, useNotiStore } from '../stores/dialogStore';
import { useAccessStore } from '../stores/accessStore';

import { shallow } from 'zustand/shallow'
import { customAlphabet } from 'nanoid'
import axios from 'axios'
import { format } from 'date-fns'
import { motion } from "framer-motion";
import { useAnimationControls } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { upperFirst, sumBy, get } from 'lodash'
import { useNavigate } from "react-router-dom";
import Loading from './Loading';

const CUSTOM_ALPHABET_NANOID = '1234567890'
const NANOID_LENGTH = 6 // total 8, plus 2 digit prefix

// admin will not access this page
export default function Cart({ }) {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const { t, i18n } = useTranslation(['translation']);

    const { cartItems, targetProductId } = useCartStore(
        (state) => ({
            cartItems: state.cartItems,
            targetProductId: state.targetProductId
        }),
        shallow
    )

    const { selectedRooms, deviceId } = useAccessStore(
        (state) => ({
            selectedRooms: state.selectedRooms,
            deviceId: state.deviceId
        }),
        shallow
    )

    const addCartItemQuantity = useCartStore((state) => state.addCartItemQuantity)
    const deductCartItem = useCartStore((state) => state.deductCartItem)
    const setDialogOpen = useDialogStore((state) => state.setOpen)
    const setCartDialogContent = useDialogStore((state) => state.setCartDialogContent)
    const setAction = useDialogStore((state) => state.setAction)
    const setOrderSuccessNoti = useNotiStore((state) => state.setOrderSuccessNoti)
    const setNotiOpen = useNotiStore((state) => state.setNotiOpen)
    const clearCart = useCartStore((state) => state.clearCart)
    const resetTargetProductId = useCartStore((state) => state.resetTargetProductId)

    const submit = () => {
        setCartDialogContent();
        setOrderSuccessNoti();
        setAction(submitOrder);
        setDialogOpen(true)
    }

    const controls = useAnimationControls();

    const submitOrder = async () => {
        setIsLoading(true);
        const nanoid = customAlphabet(CUSTOM_ALPHABET_NANOID, NANOID_LENGTH)
        const data = {
            data: {
                totalItems: cartItems.length,
                submittedAt: new Date(),
                confirmedAt: null,
                deliveredAt: null,
                cartSnapshot: JSON.stringify(cartItems),
                orderId: `00${nanoid()}-${format(new Date(), 'yyMMdd')}`,
                room: selectedRooms[0].id,
                isHiddenToUser: false,
                orderType: 'PRODUCT',
            }
        };

        try {
            const orderDone = await axios.post(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/orders`, data);
            if (orderDone) {
                setDialogOpen(false)
                setIsLoading(false);
                setNotiOpen(true);
                navigate('/order-list')
                clearCart();

                setTimeout(() => {
                    setNotiOpen(false);
                    sendSubscriptionNoti(data, orderDone, 'CLIENT_SUBMIT_ORDER');
                }, 2000);
            }

        } catch (error) {
            console.error('error')
            setIsLoading(false);
        }
    }

    const sendSubscriptionNoti = async (data, order, type) => {
        const orderId = get(order, 'data.data.id', null)
        const documentId = get(order, 'data.data.documentId', null)

        try {
            await axios.post(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/push-msg/send`, {
                totalItems: data.data.totalItems,
                room: data.data.room,
                roomName: selectedRooms[0].displayName,
                deviceId,
                type,
                order: orderId,
                orderType: data.data.orderType,
                notiUrl: `/order-history/${documentId}`,
                sentAt: new Date(),
                lang: i18n.language
            });
        } catch (error) {
            console.error('error')
        }
    }


    useEffect(() => {
        controls.start(`visible-${targetProductId || ''}`)
        resetTargetProductId();
    }, [targetProductId]);

    return (
        <div className="h-screen">
            {!!isLoading && <Loading />}
            <div className={`h-3/4 px-4 py-6  overflow-y-scroll flow-root`}>
                <ul role="list" className="-my-6 divide-y divide-gray-200">
                    {!cartItems.length && <div
                        className="mt-4 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mx-auto h-12 w-12 text-gray-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>

                        <span className="mt-2 block text-xl font-semibold text-gray-400">{t('order.cart-empty', { ns: 'translation' })}</span>
                    </div>}
                    {cartItems.map((product) => (
                        <li key={product.id} className="flex py-6">
                            <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-100">
                                <img
                                    alt={product.imageAlt}
                                    src={`${process.env.REACT_APP_STRAPI_ENDPOINT}/${product.image?.formats?.small?.url || product.image.url}`}
                                    className="h-full w-full object-contain object-center"
                                />
                            </div>

                            <div className="ml-4 flex flex-1 flex-col">
                                <div>
                                    <div className="flex justify-between font-medium text-gray-900">
                                        <p className="text-xl">
                                            <a href={product.href}>{product[`name${upperFirst(i18n.language)}`]}</a>
                                        </p>
                                        <motion.div
                                            custom={product.id}
                                            animate={controls}
                                            variants={{
                                                [`visible-${product.id}`]: {
                                                    scale: [1, 2, 2, 1, 1],
                                                    borderRadius: ["0%", "0%", "50%", "50%", "0%"],
                                                    transition: {
                                                        duration: 2,
                                                        ease: "easeInOut",
                                                        times: [0, 0.2, 0.5, 0.8, 1],
                                                        repeatDelay: 1
                                                    },
                                                }
                                            }}

                                        ><p className="min-w-[30px] text-lg ml-4 text-gray-500">x {product.quantity}</p></motion.div>
                                    </div>
                                    <p className="my-1 text-md text-gray-500">{product[`optionName${upperFirst(i18n.language)}`]}</p>
                                    {/* <p className="mt-1 text-md text-gray-500">{product.size}</p> */}
                                </div>
                                <div className="mt-1 flex flex-1 items-end justify-between text-md">
                                    {/* <p className="text-gray-500">{product.quantity}</p> */}

                                    <div className="flex">
                                        <button onClick={() => addCartItemQuantity(product)} type="button" className="rounded-md mr-2.5 bg-red-500 text-lg px-2 py-1 lg:px-4 lg:py-2 lg:text-xl font-medium text-white">
                                            {t('order.cart-add', { ns: 'translation' })}
                                        </button>
                                        {product.quantity >= 1 && <button onClick={() => deductCartItem(product)} type="button" className="rounded-md bg-blue-400 text-lg px-2 py-1 lg:px-4 lg:py-2 lg:text-xl font-medium text-white">
                                            {t('order.cart-deduct', { ns: 'translation' })}
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={`h-1/4 mt-8 bg-white sticky bottom-0 border-t-4  border-gray-400 px-4 py-2`}>
                <div className="flex justify-between text-lg font-medium text-gray-900">
                    <p>{t('order.total-items', { ns: 'translation' })}</p>
                    <p>{sumBy(cartItems, 'quantity')}</p>
                </div>
                {!!selectedRooms.length && !!cartItems.length && <div className="mt-4">
                    <button
                        type="button"
                        onClick={() => submit()}
                        className="w-full flex items-center justify-center rounded-md border border-transparent bg-red-500 px-6 py-3 text-xl font-medium text-white shadow-sm hover:bg-red-600"
                    >
                        {t('order.submit', { ns: 'translation' })}
                    </button>
                </div>}
                {(!selectedRooms.length || !cartItems.length) && <div className="mt-4">
                    <button
                        type="button"
                        className="w-full flex items-center justify-center rounded-md border border-transparent bg-gray-200 px-6 py-3 text-xl font-medium text-white shadow-sm"
                    >
                        {t('order.submit', { ns: 'translation' })}
                    </button>
                </div>}
            </div>
        </div>
    )
}