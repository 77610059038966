import React from 'react';
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import './i18n';
import './index.css';
import App from './App';
import Rooms from './Rooms';
import Order from './Order';
import OrderHistory, {
  loader as orderHistoryLoader,
} from './OrderHistory';
import OrderList from './OrderList';
import AdminOrderList from './AdminOrderList.js';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import SelectLang from './SelectLang.js';
import ReportLanding from './ReportLanding.js';

import Loading from './components/Loading.js';
import PwDialog from './components/PwDialog.js';
import Dialog from './components/Dialog.js';
import Noti from './components/Noti.js';
import AdminSettingDialog from './components/AdminSettingDialog.js';
import ReportDialog from './components/ReportDialog.js';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import DeviceReg from './DeviceReg.js';
import ProtectedRoutes from './components/ProtectedRoutes.js';
import ErrorRoutes from './components/ErrorRoutes.js';
const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: "/",
    element: <React.Suspense fallback={<Loading />}>
      <SelectLang />
      <PwDialog />
      <Dialog />
      <AdminSettingDialog />
      <Noti />
    </React.Suspense>,
    errorElement: <ErrorRoutes />
  },
  {
    path: "/reports",
    element: <React.Suspense fallback={<Loading />}>
      <ReportLanding />
      <ReportDialog />
      <Noti />
    </React.Suspense>
  },
  {
    path: "register-device",
    element: <React.Suspense fallback={<Loading />}>
      <DeviceReg />
      <Noti />
    </React.Suspense>,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "order",
        element: <React.Suspense fallback={<Loading />}>
          <Order />
        </React.Suspense>
      },
      {
        path: "order-history/:orderDocumentId",
        element: <OrderHistory />,
      },
      {
        path: "order-list",
        element: <QueryClientProvider client={queryClient}>
          <OrderList />
        </QueryClientProvider>
      }
    ],
  },
  // {
  //   path: "order",
  //   element: <React.Suspense fallback={<Loading />}>
  //     <Order />
  //   </React.Suspense>
  // },
  // {
  //   path: "order-history/:orderDocumentId",
  //   element: <OrderHistory />,
  // },
  // {
  //   path: "order-list",
  //   element: <QueryClientProvider client={queryClient}>
  //     <OrderList />
  //   </QueryClientProvider>
  // }
  // {
  //   path: "admin-order-list",
  //   element: <AdminOrderList />,
  // },
  // {
  //   path: "subscribe",
  //   element: <App />,
  // },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
