'use client'

import { useState, useEffect } from 'react'
import { sumBy, get, has, upperFirst } from 'lodash'
import axios from 'axios'
import { format, differenceInMinutes } from 'date-fns'
import { useDialogStore, useNotiStore } from '../stores/dialogStore';
import { useOrderStore } from '../stores/orderStore'
import { useAccessStore } from '../stores/accessStore';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow'
import { useNavigate } from 'react-router'
import Loading from './Loading'

export default function HistoryCart({ historyOrder }) {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const { i18n, t } = useTranslation(['translation']);
    const [currentHistoryItem, setCurrentHistoryItem] = useState(historyOrder);
    const setOrderDeliveredSuccessNoti = useNotiStore((state) => state.setOrderDeliveredSuccessNoti)
    const setOrderConfirmedSuccessNoti = useNotiStore((state) => state.setOrderConfirmedSuccessNoti)
    const setOrderDeliveredDialogContent = useDialogStore((state) => state.setOrderDeliveredDialogContent)
    const setAction = useDialogStore((state) => state.setAction)
    const setDialogOpen = useDialogStore((state) => state.setOpen)
    const setNotiOpen = useNotiStore((state) => state.setNotiOpen)

    const { isAdminAccess, deviceId } = useAccessStore(
        (state) => ({
            isAdminAccess: state.isAdminAccess,
            deviceId: state.deviceId
        }),
        shallow
    )

    useEffect(() => {
        if (!currentHistoryItem.confirmedAt && !!isAdminAccess) {
            changeOrderStatus('confirmedAt')
            setOrderConfirmedSuccessNoti();
        }
    }, [currentHistoryItem]);


    const changeOrderStatus = async (status) => {
        setIsLoading(true)
        const data = {
            data: {
                [status]: new Date(),
            }
        };

        try {
            const orderUpdated = await axios.put(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/orders/${currentHistoryItem.documentId}?populate=*`, data);
            if (orderUpdated && status === 'confirmedAt') {
                setDialogOpen(false)
                setNotiOpen(true);
                setTimeout(() => {
                    setNotiOpen(false);
                    // Hide Noti for confirming order from phone
                    // sendSubscriptionNoti(orderUpdated, 'ADMIN_CONFIRM_ORDER');
                    setIsLoading(false)
                    navigate(0)
                }, 1000);
            }
            if (orderUpdated && status === 'deliveredAt') {
                setDialogOpen(false)
                setNotiOpen(true);
                setTimeout(() => {
                    setNotiOpen(false);
                    // refresh
                    setIsLoading(false)
                    navigate(0)
                }, 1000);
            }
        } catch (error) {
            setIsLoading(false)
            console.error('error')
        }
    }

    const sendSubscriptionNoti = async (order, type) => {
        const orderId = get(order, 'data.data.id', null)
        const documentId = get(order, 'data.data.documentId', null)
        const totalItems = get(order, 'data.data.totalItems', null)
        const orderType = get(order, 'data.data.orderType', null)
        const room = get(order, 'data.data.room.id', null)
        const roomName = get(order, 'data.data.room.displayName', null)

        try {
            await axios.post(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/push-msg/send`, {
                totalItems,
                room,
                roomName,
                deviceId,
                type,
                order: orderId,
                orderType,
                notiUrl: `/order-history/${documentId}`,
                sentAt: new Date(),
                lang: i18n.language
            });
        } catch (error) {
            console.error('error')
        }
    }


    const confirmDelivered = () => {
        setOrderDeliveredDialogContent();
        setOrderDeliveredSuccessNoti();
        setAction(changeOrderStatus.bind(null, 'deliveredAt'));
        setDialogOpen(true)
    }

    return (
        <div className="h-screen">
            {isLoading && <Loading />}
            <div className={`h-3/4 md:h-3/3 px-4 py-6 pb-40 overflow-y-scroll flow-root`}>
                {!!currentHistoryItem && <ul role="list" className="-my-6 divide-y divide-gray-200">
                    {currentHistoryItem.orderType === 'PRODUCT' && currentHistoryItem.cartSnapshot.map((product) => (
                        <li key={product.id} className="flex py-6">
                            <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                {!!product.image && <img
                                    alt={product.imageAlt}
                                    src={`${process.env.REACT_APP_STRAPI_ENDPOINT}/${product.image?.formats?.small?.url || product.image.url}`}
                                    className="h-full w-full object-fill object-center"
                                />}
                            </div>

                            <div className="ml-4 flex flex-1 flex-col">
                                <div>
                                    <div className="flex justify-between text-xl font-medium text-gray-900">
                                        <h3>
                                            <a href={product.href}>{product[`name${upperFirst(i18n.language)}`]}</a>
                                        </h3>
                                        <p className="min-w-[30px] ml-4">x {product.quantity}</p>
                                    </div>
                                    <p className="my-1 text-lg text-gray-500">{product[`optionName${upperFirst(i18n.language)}`]}</p>
                                    {/* <p className="mt-1 text-md text-gray-500">{product.size}</p> */}
                                </div>
                            </div>
                        </li>
                    ))}
                    {currentHistoryItem.orderType === 'SERVICE' && <li className="flex py-6">
                        <div className="ml-4 flex flex-1 flex-col">
                            <div>
                                <div className="flex justify-between text-xl font-medium text-gray-900">
                                    <h3>
                                        <p className="text-2xl my-2">{t('other.service', { ns: 'translation' })}</p><p className="underline underline-offset-8">{currentHistoryItem.cartSnapshot[`name${upperFirst(i18n.language)}`]}</p>
                                    </h3>
                                    <p className="text-2xl min-w-[30px] ml-4">x {currentHistoryItem.totalItems}</p>
                                </div>
                                {/* <p className="mt-1 text-md text-gray-500">{product.size}</p> */}
                            </div>
                        </div>
                    </li>}
                </ul>}
            </div>
            {!!currentHistoryItem && <div className={`h-1/4 md:h-1/3 bg-white sticky bottom-0 border-t-4 border-gray-500 p-4`}>
                {/* <div className="flex justify-between text-lg font-medium text-gray-500">
                    <p># {currentHistoryItem.orderId}</p>
                </div> */}
                <div className="flex justify-between text-lg font-medium text-gray-900">
                    <p>{t('history-cart.total-items', { ns: 'translation' })}</p>
                    <p className="text-right">{has(currentHistoryItem, 'cartSnapshot.0.quantity') ? sumBy(currentHistoryItem.cartSnapshot, 'quantity') : currentHistoryItem.totalItems}</p>
                </div>
                <div className="py-1 flex justify-between text-md md:text-lg font-medium text-gray-900">
                    <p>{t('history-cart.submitted-at', { ns: 'translation' })}</p>
                    <p className="text-right">{format(currentHistoryItem.submittedAt, 'yyyy-MM-dd HH:mm')} {`(${differenceInMinutes(new Date(), new Date(currentHistoryItem.submittedAt))} ${t('history-cart.time-minutes-ago', { ns: 'translation' })})`}</p>
                </div>
                <div className="flex justify-between text-md md:text-lg font-medium text-gray-900">
                    <p>{t('history-cart.confirmed-at', { ns: 'translation' })}</p>
                    {!!currentHistoryItem.confirmedAt && <p className="text-right">{format(currentHistoryItem.confirmedAt, 'yyyy-MM-dd HH:mm')} {`(${differenceInMinutes(new Date(), new Date(currentHistoryItem.confirmedAt))} ${t('history-cart.time-minutes-ago', { ns: 'translation' })})`}</p>}
                    {!currentHistoryItem.confirmedAt && <p> - </p>}
                </div>
                {!currentHistoryItem.deliveredAt && !!currentHistoryItem.confirmedAt && <div className="mt-4 flex items-center justify-center">
                    <button
                        type="button"
                        onClick={() => confirmDelivered()}
                        className="max-w-xl w-full justify-center flex rounded-md border border-transparent bg-red-500 px-6 py-3 text-xl font-medium text-white shadow-sm hover:bg-red-600"
                    >
                        {t('history-cart.confirm-delivered', { ns: 'translation' })}
                    </button>
                </div>}
            </div>}
        </div>
    )
}