import React, { useEffect } from 'react';
import OrderListComponent from './components/OrderList';
import { useNavigate } from "react-router-dom";
import Dialog from './components/Dialog';
import Noti from './components/Noti';
import { useTranslation } from 'react-i18next';
import { useAccessStore } from './stores/accessStore';
import { shallow } from 'zustand/shallow'
import { upperFirst } from 'lodash';
export default function OrderList() {
    const { t, i18n } = useTranslation(['translation']);
    const navigate = useNavigate()
    const { selectedRooms, isAdminAccess } = useAccessStore(
        (state) => ({
            selectedRooms: state.selectedRooms,
            isAdminAccess: state.isAdminAccess
        }),
        shallow
    )

    return (
        <div className="flex min-h-full flex-col">
            <header className="shrink-0 bg-red-500 border-b">
                <div className="mx-auto flex h-fit md:min-h-16 w-full items-center justify-between px-2 md:px-5">
                    <div className="flex gap-x-4">
                        {!isAdminAccess && <span className="inline-flex items-center py-1 text-xl font-medium text-white">
                            {selectedRooms[0]?.[`name${upperFirst(i18n.language)}`]} - {t('history.title', { ns: 'translation' })}
                        </span>}
                        {!!isAdminAccess && <span className="inline-flex flex-col items-start py-1 text-xl font-medium text-white">
                            [ADMIN ACCESS]<span className="mt-2">[{!selectedRooms.length ? 'N/A' : selectedRooms.map(o => o.displayName).join('，')}]</span><span className="mt-2">{t('history.title', { ns: 'translation' })}</span>
                        </span>}
                        {!isAdminAccess && <button
                            type="button"
                            onClick={() => navigate('/order')}
                            className="my-2 inline-flex items-center rounded-md bg-black px-3 py-2 text-lg font-semibold text-white shadow-sm "
                        >
                            {t('order.back', { ns: 'translation' })}
                        </button>}
                    </div>
                    {!!isAdminAccess && <div className="px-1 min-w-fit flex items-center">
                        <button
                            type="button"
                            onClick={() => navigate('/')}
                            className="p-2 inline-flex items-center rounded-md bg-black px-3 py-2 text-lg font-semibold text-white shadow-sm "
                        >
                            {t('order.back', { ns: 'translation' })}
                        </button>
                    </div>}
                </div>
            </header>

            {/* 3 column wrapper */}
            <div className="mx-auto w-full grow flex">
                <Noti />
                <Dialog />
                <div className="overflow-y-scroll w-full h-screen shrink-0 border-t border-gray-200 w-84 border-l border-t-0 pb-24">
                    <OrderListComponent wantsToDisplayOrderHistory={true} />
                </div>
            </div>
        </div>
    );
};