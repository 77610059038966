import { useState, useEffect } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { useAdminDialogStore, usePwDialogStore, useNotiStore, useDialogStore } from '../stores/dialogStore';
import { useAccessStore } from '../stores/accessStore';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow'
import axios from 'axios'
import { get, orderBy, flattenDeep, groupBy, sumBy, head } from 'lodash'
import WebPushService from "../services/web-push";
import { customAlphabet } from 'nanoid'
import useDeviceDetection from '../hooks/useDeviceDetection';
import * as XLSX from 'xlsx';
import { format, startOfToday, endOfToday, startOfYesterday, endOfYesterday } from 'date-fns'
import qs from 'qs'
import Loading from './Loading.js';
import { writeStorage } from '@rehooks/local-storage';
import localforage from 'localforage';

const CUSTOM_ALPHABET_NANOID = '1234567890'
const NANOID_LENGTH = 5
const DEVICE_ID_PREFIX = 'D-00'

const VERSION = "1.0.16"
const VERSION_DATE = '27-12-2024'

export default function AdminSettingDialog() {
    const [isLoading, setIsLoading] = useState(false);
    const device = useDeviceDetection();
    const { t } = useTranslation(['translation']);
    const [rooms, setRooms] = useState([])
    const setDialogOpen = useAdminDialogStore((state) => state.setOpen)
    const setPwDialogOpen = usePwDialogStore((state) => state.setOpen)

    const setAdminAccess = useAccessStore((state) => state.setAdminAccess)
    const setSubscription = useAccessStore((state) => state.setSubscription)
    const setSubscribedRoom = useAccessStore((state) => state.setSubscribedRoom)
    const setSelectedRooms = useAccessStore((state) => state.setSelectedRooms)

    const setRoomOrderRemovalDialogContent = useDialogStore((state) => state.setRoomOrderRemovalDialogContent)
    const setAction = useDialogStore((state) => state.setAction)
    const setRoomOrderRemovalSuccessNoti = useNotiStore((state) => state.setRoomOrderRemovalSuccessNoti)
    const setConfirmationDialogOpen = useDialogStore((state) => state.setOpen)

    const setRoom = useAccessStore((state) => state.setRoom)
    const setDeviceId = useAccessStore((state) => state.setDeviceId)

    const setAdminSettingSuccessNoti = useNotiStore((state) => state.setAdminSettingSuccessNoti)
    const setNotiOpen = useNotiStore((state) => state.setNotiOpen)

    const { isOpen: isDialogOpen } = useAdminDialogStore(
        (state) => ({
            isOpen: state.isOpen
        }),
        shallow
    )

    const { isAdminAccess, isSubscribed, subscribedRoom, deviceId, selectedRooms, lastSelectedRoom } = useAccessStore(
        (state) => ({
            isAdminAccess: state.isAdminAccess,
            deviceId: state.deviceId,
            isSubscribed: state.isSubscribed,
            subscribedRoom: state.subscribedRoom,
            selectedRooms: state.selectedRooms,
            lastSelectedRoom: state.lastSelectedRoom
        }),
        shallow
    )

    useEffect(() => {
        const assignDeviceId = () => {
            if (!deviceId) {
                const nanoid = customAlphabet(CUSTOM_ALPHABET_NANOID, NANOID_LENGTH)
                setDeviceId(`${DEVICE_ID_PREFIX}${nanoid()}`)
            }
        }
        const getRooms = async () => {
            try {
                const result = await axios.get(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/rooms`);
                if (result) {
                    setRooms(get(result, 'data.data', null))
                }
            } catch (error) {
                console.error(error)
            }
        }
        getRooms();
        assignDeviceId();

        }, []);

        const setLoading = (value) => {
            if (window.location.hostname !== 'localhost') {
                setIsLoading(value)
            }
        }

        useEffect(() => {
            
            const attemptSubscribe = async () => {
                setLoading(true);
                console.log('attemptSubscribe', WebPushService.hasPermission())
                try {
                    if (!WebPushService.hasPermission()) {
                        await WebPushService.requestPermission();
                    }

                    let subscription = await WebPushService.getSubscription();
                    if (!subscription) {
                        subscription = await WebPushService.subscribe();
                    }

                    const originalSub = await localforage.getItem('sub-rooms');
                    console.log('originalSub', originalSub)
                    let promises = [];
                    if (!originalSub || originalSub !== selectedRooms) {
                        for (const selectedRoom of selectedRooms) {
                            let result = fetch('https://4eucvvjfl5fapjq5d2azl3x6ka.appsync-api.us-east-1.amazonaws.com/graphql', {
                                method: "POST",
                                headers: { "x-api-key": 'da2-zr4x2m7ttbd6tgfpfdrzgzkszi' },
                                body: JSON.stringify({
                                    query: `mutation($topic: String, $deviceId: String, $subscription: String) {subscribe(topic: $topic, deviceId: $deviceId, subscription: $subscription)}`,
                                    variables: { topic: `adm-${selectedRoom.id}`, deviceId, subscription: JSON.stringify(subscription) }
                                })
                            });
                            promises.push(result)
                        }
                        try { 
                            await Promise.allSettled(promises);
                            await localforage.setItem('sub-rooms', selectedRooms);
                        } catch (error) {
                            console.error('Error occurred:', error);
                        }
                        //await WebPushService.subscribeTo(subscription, deviceId, lastSelectedRoom, isAdminAccess, selectedRooms.map(o => o.id).includes(lastSelectedRoom.id));
                    }

                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    // setSubscription(false);
                    // TODO: error noti
                    console.error(error);
                }
            }
            attemptSubscribe();
        }, [selectedRooms]);

        // TODO: currentRoom logic on order filter should be extended to selectedRooms
        // TODO: check if subscription is ok (admin or non admin)

        // const { currentRoom } = useAccessStore(
        //     (state) => ({
        //         currentRoom: state.currentRoom,
        //     }),
        //     shallow
        // )

        const closeAllDialog = () => {
            setDialogOpen(false);
            setPwDialogOpen(false);
        }

        // const setTargetRoom = (targetRoom) => {
        //     setRoom(targetRoom)
        //     setAdminSettingSuccessNoti();
        //     setNotiOpen(true);

        //     setTimeout(() => {
        //         setNotiOpen(false);
        //     }, 1000);
        // }

        // const toggleAdminAccess = (o) => {
        //     setAdminAccess(o)
        //     setAdminSettingSuccessNoti();
        //     setNotiOpen(true);

        //     setTimeout(() => {
        //         setNotiOpen(false);
        //     }, 1000);
        // }

        // const toggleSubscription = (o) => {
        //     setSubscription(o)
        //     setAdminSettingSuccessNoti();
        //     setNotiOpen(true);

        //     setTimeout(() => {
        //         setNotiOpen(false);
        //         if (!!o) {
        //             attemptSubscribe();
        //         }
        //     }, 1000);
        // }

        // const toggleRoomSubscription = (o, currentRoom) => {
        //     setSubscribedRoom(!!o ? currentRoom : null)
        //     setAdminSettingSuccessNoti();
        //     setNotiOpen(true);

        //     setTimeout(() => {
        //         setNotiOpen(false);
        //         if (!!o) {
        //             attemptSubscribe(currentRoom);
        //         }
        //     }, 1000);
        // }

        const removeRoomOrder = () => {
            setRoomOrderRemovalDialogContent(selectedRooms[0]?.displayName);
            setRoomOrderRemovalSuccessNoti();
            setAction(roomOrdersRemoval);
            setConfirmationDialogOpen(true)
        }

        const roomOrdersRemoval = async () => {
            const data = {
                room: selectedRooms[0].id
            };

            try {
                const ordersRemoved = await axios.post(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/order/room-order-removal`, data);
                if (ordersRemoved) {
                    setConfirmationDialogOpen(false)
                    setNotiOpen(true);
                    setTimeout(() => {
                        setNotiOpen(false);
                    }, 2000);
                }

            } catch (error) {
                console.error('error')
                //TODO error handling
            }
        }

        const generateRoomData = async (start, end, reportName) => {
            try {
                const queryString = qs.stringify({
                    sort: ['createdAt:asc'],
                    filters: {
                        createdAt: {
                            $gt: start,
                            $lt: end
                        },
                        deliveredAt: {
                            $null: false
                        }
                    },
                    populate: '*',
                }, {
                    encodeValuesOnly: true, // prettify URL
                });
                const orders = await axios.get(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/orders?${queryString}`);
                if (orders) {
                    /* flatten objects */
                    const data = orderBy(orders.data.data, ['createdAt'], ['asc'])
                    const rows = data.map(row => ({
                        room: row.room.displayName,
                        orderId: row.orderId,
                        orderType: row.orderType,
                        orderItem: row.orderType === 'SERVICE' ? row.cartSnapshot.nameTc : row.cartSnapshot.map(o => {
                            return `${o.nameTc || o.name}${o.optionNameTc ? `, ${o.optionNameTc}` : ''} x ${o.quantity}`
                        }).join(', '),
                        totalItems: row.totalItems,
                        submittedAt: !row.submittedAt ? '' : format(row.submittedAt, 'yyyy-MM-dd HH:mm:ss'),
                        confirmedAt: !row.confirmedAt ? '' : format(row.confirmedAt, 'yyyy-MM-dd HH:mm:ss'),
                        deliveredAt: !row.deliveredAt ? '' : format(row.deliveredAt, 'yyyy-MM-dd HH:mm:ss')
                    }));

                    /* generate worksheet and workbook */
                    const worksheet = XLSX.utils.json_to_sheet(rows);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Room Orders");

                    /* fix headers */
                    XLSX.utils.sheet_add_aoa(worksheet, [["房間名稱", "訂單號碼", "訂單類型", "訂單內容", "總數", "送出時間", "確認時間", "完成時間"]], { origin: "A1" });
                    /* calculate column width */
                    worksheet["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 150 }, { wch: 10 }, { wch: 30 }, { wch: 30 }, { wch: 30 }];
                    /* create an XLSX file and try to save to Presidents.xlsx */
                    XLSX.writeFile(workbook, `${reportName}.xlsx`, { compression: true });
                }

            } catch (error) {
                console.error(error)
                // TODO error handling
            }
        }

        const generateProductData = async (start, end, reportName) => {
            try {
                const queryString = qs.stringify({
                    sort: ['createdAt:asc'],
                    filters: {
                        createdAt: {
                            $gt: start,
                            $lt: end
                        }
                    },
                    populate: '*',
                }, {
                    encodeValuesOnly: true, // prettify URL
                });
                const orders = await axios.get(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/orders?${queryString}`);
                if (orders) {
                    /* flatten objects */
                    const data = orderBy(orders.data.data, ['createdAt'], ['asc'])
                    const productsOnly = data.filter(o => o.orderType === 'PRODUCT')
                    const allCartSnapshot = flattenDeep(productsOnly.map(o => {
                        let x = o.cartSnapshot.map(c => {
                            return {
                                ...c,
                                orderId: o.orderId
                            }
                        });
                        return x
                    }));
                    const grouped = groupBy(allCartSnapshot, 'id');
                    const result = [];
                    for (const groupItem in grouped) {
                        const product = {
                            ...head(grouped[groupItem]),
                            orderIds: grouped[groupItem].map(o => o.orderId).join(', '),
                            totalQuantity: sumBy(grouped[groupItem], 'quantity')
                        }
                        result.push(product)
                    }

                    const sortedResult = orderBy(result, ['totalQuantity'], ['desc'])
                    const rows = sortedResult.map(row => ({
                        product: `${row.nameTc}${row.optionNameTc ? `, ${row.optionNameTc}` : ''}`,
                        orderIds: row.orderIds,
                        totalQuantity: row.totalQuantity,
                    }));

                    /* generate worksheet and workbook */
                    const worksheet = XLSX.utils.json_to_sheet(rows);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

                    /* fix headers */
                    XLSX.utils.sheet_add_aoa(worksheet, [["產品名稱", "訂單號碼", "總數量"]], { origin: "A1" });
                    /* calculate column width */
                    worksheet["!cols"] = [{ wch: 40 }, { wch: 150 }, { wch: 20 }];
                    /* create an XLSX file and try to save to Presidents.xlsx */
                    XLSX.writeFile(workbook, `${reportName}.xlsx`, { compression: true });
                }

            } catch (error) {
                console.error(error)
                // TODO error handling
            }
        }


        return (
            <Dialog open={isDialogOpen} onClose={setDialogOpen} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                {!!isLoading && <Loading />}

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div>
                                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                    </svg>
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <DialogTitle as="h3" className="text-2xl mb-2 font-semibold text-gray-900">
                                        {t('admin.admin-setting', { ns: 'translation' })}
                                    </DialogTitle>
                                    <div className="tracking-widest text-gray-500">{isAdminAccess ? t('admin.admin-device', { ns: 'translation' }) : t('admin.client-device', { ns: 'translation' })}</div>
                                    <div className="tracking-widest text-gray-500">{t('admin.device-id', { ns: 'translation' })} [{deviceId || 'NEW'}]</div>
                                    <div className="text-xs tracking-widest text-gray-400">{navigator.userAgent.toLowerCase()}</div>
                                    <div className="mb-6 text-xs tracking-widest text-gray-400">Viewport width {window.innerWidth}</div>
                                    <div className="mb-6 text-xs tracking-widest text-gray-400">Version {VERSION}</div>
                                    <div className="mb-6 text-xs tracking-widest text-gray-400">Date {VERSION_DATE}</div>
                                    {/* 
                                <fieldset>
                                    <div className="flex items-center justify-between">
                                        <div className="text-md font-medium text-gray-900">{t('admin.ava-rooms', { ns: 'translation' })}</div>
                                        <div className="text-sm font-medium text-gray-500">{t('admin.current-selected-room', { ns: 'translation' })} {currentRoom?.displayName || 'N/A'}</div>

                                    </div>

                                    {!!rooms.length && <RadioGroup value={currentRoom} onChange={(o) => setTargetRoom(o)} className="mt-2 grid grid-cols-3 gap-3">
                                        {rooms.map((option) => (
                                            <Radio
                                                key={option.documentId}
                                                value={option}
                                                checked={!!selectedRooms.includes(option.id)}
                                                disabled={option?.displayName === currentRoom?.displayName}
                                                className={classNames(
                                                    'cursor-pointer focus:outline-none flex items-center justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold uppercase text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-red-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-red-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-red-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset',
                                                )}
                                            >
                                                {option.displayName}
                                            </Radio>
                                        ))}
                                    </RadioGroup>}
                                </fieldset> */}

                                    <fieldset>
                                        <legend className="text-lg font-semibold text-gray-900">{t('admin.ava-rooms', { ns: 'translation' })}</legend>
                                        <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-2">
                                            {rooms.map((room, idx) => (
                                                <div key={idx} onClick={() => setSelectedRooms(room)} className={`${!!selectedRooms.map(o => o.id).includes(room.id) ? 'bg-red-500' : ''} rounded-md border flex items-center justify-center `}>
                                                    <div className="px-2 py-2 text-center min-w-0 flex-1 text-md">
                                                        <label className={`${!!selectedRooms.map(o => o.id).includes(room.id) ? 'text-white font-semibold' : 'text-gray-900 font-medium'} select-none`}>
                                                            {room.displayName} <span className="text-xs">({room.id})</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </fieldset>

                                    {!isAdminAccess && !!selectedRooms.length && <fieldset className="mt-8 flex items-center justify-center">
                                        <legend className="text-lg mb-2 font-semibold text-gray-900">{t('admin.clear-room-order', { ns: 'translation' })}</legend>
                                        <button
                                            type="button"
                                            onClick={() => removeRoomOrder()}
                                            className="max-w-xs w-full rounded-md border border-transparent bg-red-500 px-6 py-3 text-xl font-medium text-white shadow-sm hover:bg-red-600"
                                        >
                                            {t('admin.button-clear-room-order', { ns: 'translation' })} - {selectedRooms[0]?.displayName}
                                        </button>
                                    </fieldset>}

                                    {/* {!!isAdminAccess && <fieldset className="mt-8 flex items-center justify-center">
                                    <div className="w-full flex flex-col">
                                        <legend className="text-lg mb-1 font-semibold text-gray-900">下載銷售報表 (XLSX)</legend>
                                        <span className="text-sm mb-2 text-gray-500">* 只有狀態為<b>已完成</b>的訂單才會顯示於表報內</span>
                                        <div className="grid grid-cols-2 w-full gap-x-2 gap-y-2">
                                            <button
                                                type="button"
                                                onClick={() => generateProductData(startOfToday(), endOfToday(), `產品銷售報表(今日)_${format(startOfToday, 'yyyyMMdd')}`)}
                                                className="max-w-xs w-full rounded-md border border-gray-300 bg-white text-md px-2 py-2 font-medium text-gray-500 shadow-sm"
                                            >
                                                產品銷售 (今日)
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => generateProductData(startOfYesterday(), endOfYesterday(), `產品銷售報表(昨日)_${format(startOfYesterday, 'yyyyMMdd')}`)}
                                                className="max-w-xs w-full rounded-md border border-gray-300 bg-white px-2 py-2  text-md font-medium text-gray-500 shadow-sm"
                                            >
                                                產品銷售 (昨日)
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => generateRoomData(startOfToday(), endOfToday(), `房間訂單報表(今日)_${format(startOfToday, 'yyyyMMdd')}`)}
                                                className="max-w-xs w-full rounded-md border border-gray-300 bg-white px-2 py-2  text-md font-medium text-gray-500 shadow-sm"
                                            >
                                                房間訂單 (本日)
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => generateRoomData(startOfYesterday(), endOfYesterday(), `房間訂單報表(昨日)_${format(startOfYesterday, 'yyyyMMdd')}`)}
                                                className="max-w-xs w-full rounded-md border border-gray-300 bg-white px-2 py-2  text-md font-medium text-gray-500 shadow-sm"
                                            >
                                                房間訂單 (昨日)
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>} */}


                                    {/* <fieldset>
                                    <div className="mt-8 flex items-center justify-between">
                                        <div className="text-md font-medium text-gray-900">{t('admin.enable-admin-access', { ns: 'translation' })}</div>
                                    </div>

                                    <div className="mt-1 flex items-center justify-start">
                                        <Switch
                                            checked={isAdminAccess}
                                            onChange={(o) => toggleAdminAccess(o)}
                                            className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-red-100 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 data-[checked]:bg-red-600"
                                        >
                                            <span
                                                aria-hidden="true"
                                                className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                            />
                                        </Switch>
                                    </div>
                                </fieldset>

                                <fieldset>
                                    <div className="mt-8 flex items-center justify-between">
                                        <div className="text-md font-medium text-gray-900">{t('admin.enable-subscription', { ns: 'translation' })}</div>
                                    </div>

                                    <div className="mt-1 flex items-center justify-start">
                                        <Switch
                                            checked={isSubscribed}
                                            onChange={(o) => toggleSubscription(o)}
                                            className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-red-100 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 data-[checked]:bg-red-600"
                                        >
                                            <span
                                                aria-hidden="true"
                                                className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                            />
                                        </Switch>
                                    </div>
                                </fieldset>

                                {currentRoom && <fieldset>
                                    <div className="mt-8 flex items-center justify-between">
                                        <div className="text-md font-medium text-gray-900">{t('admin.enable-subscription', { ns: 'translation' })} {subscribedRoom? `- ${subscribedRoom.displayName}`: ''}</div>
                                    </div>

                                    <div className="mt-1 flex items-center justify-start">
                                        <Switch
                                            checked={!!subscribedRoom}
                                            onChange={(o) => toggleRoomSubscription(o, currentRoom)}
                                            className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-red-100 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 data-[checked]:bg-red-600"
                                        >
                                            <span
                                                aria-hidden="true"
                                                className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                            />
                                        </Switch>
                                    </div>
                                </fieldset>} */}


                                </div>
                            </div>
                            <div className="mt-10 sm:grid sm:grid-flow-row-dense sm:gap-3">
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => closeAllDialog()}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-1 sm:mt-0"
                                >
                                    {t('admin.close', { ns: 'translation' })}
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        )
    }