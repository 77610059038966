import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Cart from './components/Cart';
import Products from './components/Products';
import Services from './components/Services';
import Dialog from './components/Dialog';
import Noti from './components/Noti';
import { useTranslation } from 'react-i18next';
import { useCartStore } from './stores/orderStore';
import { shallow } from 'zustand/shallow'
import { useAccessStore } from './stores/accessStore';
import { upperFirst } from 'lodash';
// admin will not enter this page
export default function Order() {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation(['translation']);
    const backToCategory = useCartStore((state) => state.backToCategory)
    const { isCategorySelected } = useCartStore(
        (state) => ({
            isCategorySelected: state.isCategorySelected
        }),
        shallow
    )
    const { selectedRooms, isAdminAccess } = useAccessStore(
        (state) => ({
            selectedRooms: state.selectedRooms,
            isAdminAccess: state.isAdminAccess
        }),
        shallow
    )

    useEffect(() => {
        if (!!isAdminAccess) {
            navigate('/order-list')
        }
    }, [isAdminAccess]);

    return (
        <div className="flex min-h-full flex-col">
            <Noti />
            <header className="shrink-0 bg-red-500 border-b">
                <div className="mx-auto flex h-16 w-full items-center justify-between px-4">
                    <div className="flex gap-x-4">
                        {!isAdminAccess && <span className="inline-flex items-center py-1 text-xl font-medium text-white">
                            {selectedRooms[0]?.[`name${upperFirst(i18n.language)}`]} - {t('order.new-order', { ns: 'translation' })}
                        </span>}
                        {/* {!!isAdminAccess && <span className="inline-flex items-center py-1 text-xl font-medium text-white">
                            [ADMIN ACCESS] - {currentRoom?.displayName||'[NO ROOMS SELECTED]'} - {t('order.new-order', { ns: 'translation' })}
                        </span>} */}
                        {!isAdminAccess && <button
                            type="button"
                            onClick={() => navigate('/')}
                            className="inline-flex items-center rounded-md bg-black px-3 py-2 text-lg font-semibold text-white shadow-sm "
                        >
                            {t('order.back', { ns: 'translation' })}
                        </button>}
                    </div>
                    <div className="flex items-center gap-x-4">
                        <button
                            type="button"
                            onClick={() => navigate('/order-list')}
                            className="inline-flex items-center rounded-md bg-black px-3 py-2 text-lg font-semibold text-white shadow-sm "
                        >
                            {t('order.order-history', { ns: 'translation' })}
                        </button>
                        
                    </div>
                </div>
            </header>

            {/* 3 column wrapper */}
            <Dialog />
            <div className="overflow-y-hidden mx-auto w-full grow flex">
                {/* Left sidebar & main wrapper */}
                <div className="flex-1 h-screen w-3/5 lg:w-2/3">
                    <div className="overflow-y-scroll h-3/4 border-b border-gray-200 pb-4 pb-10 relative">
                    <div className={`w-full py-2 mb-4 bg-gray-600 `}>
                        <button
                            type="button"
                            onClick={() => backToCategory()}
                            className={`${!!isCategorySelected ? 'visible' : 'invisible'} sticky top-0 left-6 h-10 z-10 rounded-md bg-red-600 px-6 tracking-widest text-xl font-semibold text-white shadow-sm`}
                        >
                            {t('order.back', { ns: 'translation' })}
                        </button>
                        </div>
                        <Products />
                        
                    </div>

                    <div className="overflow-y-scroll h-1/4 pb-20 ">
                        <Services />
                    </div>
                </div>

                <div className="h-screen shrink-0 border-t border-gray-200 border-l border-t-0 pb-20 w-2/5 lg:w-1/3">
                    <Cart />
                </div>
            </div>
        </div>
    );
};