import { useEffect, useState } from "react";
import { useCartStore } from '../stores/orderStore';
import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next';
import { upperFirst, orderBy, get } from 'lodash'
import axios from 'axios'

const categories = [
  {
    id: 1,
    nameEn: 'Water',
    nameSc: '水',
    nameTc: '水',
    imageSrc: `${process.env.REACT_APP_STRAPI_ENDPOINT}/uploads/small_Picture_2_a04fc4f3d2.jpg`,
    href: '#'
  },
  {
    id: 2,
    nameEn: 'Beverages',
    nameSc: '饮品',
    nameTc: '飲品',
    imageSrc: `${process.env.REACT_APP_STRAPI_ENDPOINT}/uploads/small_Picture_3_1d15b0af1f.jpg`,
    href: '#'
  },
  {
    id: 3,
    nameEn: 'Light Snacks',
    nameSc: '轻食',
    nameTc: '輕食',
    imageSrc: `${process.env.REACT_APP_STRAPI_ENDPOINT}/uploads/small_Picture_2_7276b93a29.jpg`,
    href: '#'
  }
]

export default function Products() {
  const { i18n } = useTranslation(['translation']);
  const addCartItem = useCartStore((state) => state.addCartItem)
  const selectCategory = useCartStore((state) => state.selectCategory)
  const showOptionList = useCartStore((state) => state.showOptionList)
  const backToCategory = useCartStore((state) => state.backToCategory)

  const [productList, setProductList] = useState([]);

  const { isCategorySelected, products, showOptionsWithSelectedProductId } = useCartStore(
    (state) => ({
      isCategorySelected: state.isCategorySelected,
      products: state.products,
      showOptionsWithSelectedProductId: state.showOptionsWithSelectedProductId
    }),
    shallow
  )

  const getProducts = () => {
    if (showOptionsWithSelectedProductId) {
      return products.filter((p) => {
        if (!p.belongsToProductId) return false;
        return p.belongsToProductId.id === showOptionsWithSelectedProductId
      })
    }

    return products.filter((p) => {
      return !p.isOption
    })
  }


  useEffect(() => {
    const getProducts = async () => {
      try {
        const result = await axios.get(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/products?populate=*`);
        if (result) {
          setProductList(get(result, 'data.data', null))
        }
      } catch (error) {
        console.error(error)
      }
    }
    getProducts();
    backToCategory();
  }, []);

  return (
    <div className="bg-white w-full">
      {!!productList.length && <div className="w-full mx-auto px-14">

        {!isCategorySelected && <div className="mt-2 grid grid-cols-2 gap-x-8 gap-y-4 md:grid-cols-3">
          {categories.map((category, idx) => (
            <div onClick={() => selectCategory(productList, category.id)} key={category.id} className="pb-2 group relative">
              <div className="w-full h-[140px] lg:w-[150px] border border-gray-100 overflow-hidden rounded-md bg-white">
                <img
                  src={category.imageSrc}
                  className="w-full h-full object-contain object-center"
                />
              </div>
              <h3 className="mt-4 mb-1 text-md text-gray-500 font-medium tracking-wide">
                <a href='#'>
                  <span className="absolute inset-0" />
                  {category[`name${upperFirst(i18n.language)}`]}
                </a>
              </h3>
            </div>
          ))}
        </div>}


        {!!isCategorySelected && <div className="mt-2 grid grid-cols-2 gap-x-8 gap-y-4 md:grid-cols-3">
          {orderBy(getProducts(), ['sequence'], ['asc']).map((product, idx) => {

            if (product.hasOption && !showOptionsWithSelectedProductId) {
              return <div onClick={() => showOptionList(product)} key={product.id} className="pb-2 group relative">
                <div className="w-full h-[140px] lg:w-[150px] border border-gray-100 overflow-hidden rounded-md bg-white">
                  <img
                    src={`${process.env.REACT_APP_STRAPI_ENDPOINT}/${product.image?.formats?.small?.url || product.image.url}`}
                    className="w-full h-full object-contain object-center"
                  />
                </div>
                <h3 className="mt-4 mb-1 text-md text-gray-500 font-medium tracking-wide">
                  <a href='#'>
                    <span className="absolute inset-0" />
                    {product[`name${upperFirst(i18n.language)}`]}
                  </a>
                </h3>
                <p className="my-1 text-md text-gray-500">{product[`optionName${upperFirst(i18n.language)}`]}</p>
                {/* <p className="mt-1 text-md font-medium text-gray-900">{product.price}</p> */}
              </div>
            }

            return <div onClick={() => addCartItem(product)} key={product.id} className="pb-2 group relative">
              <div className="w-full h-[140px] lg:w-[150px] border border-gray-50 overflow-hidden rounded-md bg-white">
                <img
                  src={`${process.env.REACT_APP_STRAPI_ENDPOINT}/${product.image?.formats?.small?.url || product.image.url}`}
                  className="w-full h-full object-contain object-center"
                />
              </div>
              <h3 className="mt-4 mb-1 text-md text-gray-500 font-medium tracking-wide">
                <a href='#'>
                  <span className="absolute inset-0" />
                  {product[`name${upperFirst(i18n.language)}`]}
                </a>
              </h3>
              <p className="my-1 text-md text-gray-500">{product[`optionName${upperFirst(i18n.language)}`]}</p>
              {/* <p className="mt-1 text-md font-medium text-gray-900">{product.price}</p> */}
            </div>
          }

          )}
        </div>}


      </div>}
    </div>
  )
}
