import axios from 'axios'

class WebPushService {
    static hasPermission() {
        return Notification.permission === "granted";
    }

    static async requestPermission() {
        return await Notification.requestPermission();
    }

    static async getSubscription() {
        return await navigator.serviceWorker.ready.then(async (registration) => {
            return await registration.pushManager.getSubscription();
        });
    }

    static async subscribe() {
        function urlB64ToUint8Array(base64String) {
            const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
            const base64 = (base64String + padding)
              .replace(/\-/g, "+")
              .replace(/_/g, "/");
          
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);
          
            for (let i = 0; i < rawData.length; ++i) {
              outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
          }
          
        if (!'serviceWorker' in navigator) {
            console.error('service worker cannot be found');
            return;
        }

        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array('BCRVhNEggbfwloegcbwFT7Itku5T5uAbCLF7RaQTB0q1x1DxEO-2PSJW7XUy2DcDOS7f9pit8NfcqyM5wPUd1eI'),
        });
        return subscription;
    }

    static async unsubscribe() {
        const subscription = await this.getSubscription();
        if (subscription) {
            await subscription.unsubscribe();
        }
        return subscription;
    }

    static async subscribeTo(subscription, deviceId, room, isAdminAccess, isSelected) {
        let sub = JSON.parse(JSON.stringify(subscription));
        let token = sub.keys.p256dh;
        let auth = sub.keys.auth;
        const data = {
            deviceId,
            sentAt: new Date(),
            endpoint: sub.endpoint,
            token,
            auth,
            room: room.id,
            isAdmin: isAdminAccess,
            isSelected
        }
        return await axios.post(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/push-msg/subscribe`, data);
    }
}

export default WebPushService;