import React, { useEffect, useState } from 'react';
import Cart from './components/Cart';
import { useLoaderData } from "react-router-dom";
import axios from 'axios'
import { useOrderStore } from './stores/orderStore'
import { useAccessStore } from './stores/accessStore'
import { shallow } from 'zustand/shallow'
import { get } from 'lodash'
import HistoryCart from './components/HistoryCart';
import { useNavigate, useParams } from "react-router-dom";
import Dialog from './components/Dialog';
import Noti from './components/Noti';
import { useTranslation } from 'react-i18next';
import Loading from './components/Loading';
import { upperFirst } from 'lodash';
// export async function loader({ params }) {
//     try {
//         const result = await axios.get(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/orders/${params.orderDocumentId}?populate=*`);
//         if (result) {
//             const order = get(result, 'data.data', null);
//             return { order }
//         }
//         return { order: null };
//     } catch (error) {
//         console.error(error)
//     }
// }

export default function OrderHistory() {
    const [order, setOrder] = useState();
    const params = useParams();
    const { t, i18n } = useTranslation(['translation']);
    const navigate = useNavigate()

    const { isAdminAccess } = useAccessStore(
        (state) => ({
            isAdminAccess: state.isAdminAccess
        }),
        shallow
    )

    useEffect(() => {
        const getOrder = async () => {
            const result = await axios.get(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/orders/${params.orderDocumentId}?populate=*`);
            if (result) {
                const orderFromApi = get(result, 'data.data', null);
                setOrder(orderFromApi)
            }
        }
        getOrder();
    }, []);

    if (!order) {
        return <Loading />
    } else if (!!order) {
        return (
            <div className="flex min-h-full flex-col">
                <header className="shrink-0 bg-red-500 border-b">
                    <div className="mx-auto flex h-fit md:min-h-16 w-full items-center justify-between px-2 md:px-5">
                        {!!order && <div className="flex gap-x-2">
                            <span className="px-2 md:px-0 flex-col md:flex-row inline-flex items-start md:items-center py-1 text-xl font-medium text-white">
                                {order.room[`name${upperFirst(i18n.language)}`]} <span className="mx-0 md:ml-4 tracking-widest text-gray-800">{t('history-cart.order', { ns: 'translation' })} # {order.orderId}</span>
                            </span>
                            {!isAdminAccess && <div className="py-4 h-full flex justify-center items-center"><button
                                type="button"
                                onClick={() => navigate('/order-list')}
                                className="justify-center mt-2 justify-center flex items-center rounded-md bg-black px-4 py-2 text-lg font-semibold text-white shadow-sm "
                            >
                                {t('order.back', { ns: 'translation' })}
                            </button></div>}
                            {!!isAdminAccess && <button
                                type="button"
                                onClick={() => navigate('/order')}
                                className="justify-center text-center w-20 h-14 mt-2 flex items-center rounded-md bg-black px-3 py-2 text-lg font-semibold text-white shadow-sm "
                            >
                                {t('order.back', { ns: 'translation' })}
                            </button>}
                        </div>}
                        <div className="px-1 min-w-fit flex items-center gap-x-4">
                            {/* <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-black px-3 py-2 text-md font-semibold text-white shadow-sm "
                            >
                                Switch To Another Room
                            </button> */}
                            {/* <button
                                type="button"
                                onClick={() => navigate('/order-list')}
                                className="p-2 inline-flex items-center rounded-md bg-black px-3 py-2 text-lg font-semibold text-white shadow-sm "
                            >
                                {t('order.back', { ns: 'translation' })}
                            </button> */}
                        </div>
                    </div>
                </header>

                {/* 3 column wrapper */}
                <div className="mx-auto w-full grow flex">
                    <Noti />
                    <Dialog />
                    <div className="w-full h-screen shrink-0 border-t border-gray-200 w-84 border-l border-t-0 pb-24">
                        {!!order && <HistoryCart wantsToDisplayOrderHistory={true} historyOrder={order} />}
                    </div>
                </div>
            </div>
        )
    }
};