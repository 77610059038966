
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useOrderStore } from '../stores/orderStore'
import { useDialogStore, useNotiStore } from '../stores/dialogStore';
import { useAccessStore } from '../stores/accessStore';
import { shallow } from 'zustand/shallow'
import { get, upperFirst } from 'lodash'
import { format, differenceInMinutes } from 'date-fns'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import qs from 'qs'
import { useQuery } from 'react-query'

const API_FETCH_INTERVAL_MS = 3000;

const statuses = {
    'history.status.completed': 'text-green-700 bg-green-50 ring-green-600/20',
    'history.status.in-progress': 'text-yellow-600 bg-yellow-50 ring-yellow-500/10',
    'history.status.confirmed': 'text-red-800 bg-red-50 ring-red-600/20',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function OrderList() {
    const { i18n, t } = useTranslation(['translation']);

    const [isUpdated, setIsUpdated] = useState(false)
    const setOrderDeliveredSuccessNoti = useNotiStore((state) => state.setOrderDeliveredSuccessNoti)
    const setOrderDeliveredDialogContent = useDialogStore((state) => state.setOrderDeliveredDialogContent)
    const setAction = useDialogStore((state) => state.setAction)
    const setDialogOpen = useDialogStore((state) => state.setOpen)
    const setNotiOpen = useNotiStore((state) => state.setNotiOpen)

    const navigate = useNavigate();

    const { orders } = useOrderStore(
        (state) => ({
            orders: state.orders,
        }),
        shallow
    )

    const { selectedRooms, isAdminAccess } = useAccessStore(
        (state) => ({
            selectedRooms: state.selectedRooms,
            isAdminAccess: state.isAdminAccess
        }),
        shallow
    )

    const setOrders = useOrderStore((state) => state.setOrders)

    const { refetch } = useQuery('orderData', () => getOrders(), {
        refetchInterval: API_FETCH_INTERVAL_MS,
        refetchIntervalInBackground: true,
    })

    const getOrders = async () => {
        const queryString = qs.stringify({
            sort: ['createdAt:desc'],
            filters: {
                room: {
                    $in: selectedRooms.map(o => o.id),
                },
                isHiddenToUser: {
                    $eq: false
                }
            },
            populate: '*',
        }, {
            encodeValuesOnly: true, // prettify URL
        });
        try {
            const query = `${process.env.REACT_APP_STRAPI_ENDPOINT}/api/orders?${queryString}`;
            const result = await axios.get(query);
            if (result) {
                setOrders(get(result, 'data.data', []))
                return get(result, 'data.data', [])
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        refetch();
    }, [isUpdated]);

    const getStatus = (order) => {
        if (order && order.deliveredAt) {
            return 'history.status.completed'
        } else if (order && order.confirmedAt) {
            return 'history.status.confirmed'
        } else {
            return 'history.status.in-progress'
        }
    }

    const changeOrderStatus = async (status, documentId) => {
        const data = {
            data: {
                [status]: new Date(),
            }
        };

        try {
            const orderUpdated = await axios.put(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/orders/${documentId}`, data);
            if (orderUpdated && status === 'deliveredAt') {
                setDialogOpen(false)
                setNotiOpen(true);
                setTimeout(() => {
                    setNotiOpen(false);
                    setIsUpdated(true);
                }, 2000);
            }
        } catch (error) {
            console.error('error')
        }
    }

    const confirmDelivered = (documentId) => {
        setOrderDeliveredDialogContent();
        setOrderDeliveredSuccessNoti();
        setAction(changeOrderStatus.bind(null, 'deliveredAt', documentId));
        setDialogOpen(true)
    }

    if (!orders.length) {
        return <ul role="list" className="px-4 divide-y divide-gray-200">
            <div className="mt-4 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mx-auto h-12 w-12 text-gray-400"><path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path></svg>
                <span className="mt-2 block text-xl font-semibold text-gray-400">{t('order.order-empty', { ns: 'translation' })}</span>
            </div>
        </ul>
    }

    return (
        !!orders.length && <ul role="list" className="divide-y divide-gray-100 px-2 lg:px-10 py-1">
            {orders.map((order) => (
                <li key={order.id} className="flex flex-col md:flex-row items-center justify-between gap-x-6 py-5">
                    <div className="px-2 w-full">
                        <div className="flex flex-col md:flex-row items-start gap-x-3">
                            <p className="text-xl font-semibold leading-6 text-gray-900">{order?.room?.[`name${upperFirst(i18n.language)}`]}</p>
                            <p
                                className={classNames(statuses[getStatus(order)], 'md:my-0 my-2 whitespace-nowrap rounded-md px-1.5 py-0.5 text-md font-medium ring-1 ring-inset',
                                )}
                            >
                                {t(getStatus(order), { ns: 'translation' })}
                            </p>
                        </div>
                        {<div className="mt-1 flex items-center gap-x-2 text-md leading-5 text-gray-500">
                            <p className="whitespace-nowrap font-medium">
                                # {order.orderId}
                            </p>
                        </div>}
                        {order.orderType === 'SERVICE' && <div className="my-2 flex items-center gap-x-2 text-lg leading-5 text-gray-800">
                            <p className="whitespace-nowrap font-medium">
                                {order.cartSnapshot[`name${upperFirst(i18n.language)}`]}
                            </p>
                        </div>}
                        {order.orderType !== 'SERVICE' && <div className="my-2 flex items-center gap-x-2 text-lg leading-5 text-gray-800">
                            <p className="whitespace-nowrap font-medium">
                                {t('history.total-ordered-item', { ns: 'translation' })} {order.totalItems}
                            </p>
                        </div>}
                        <div className="mt-1 flex-col items-center gap-x-2 text-sm leading-5 text-gray-500 font-medium">
                            <p className="whitespace-nowrap">
                                {t('history.submitted-at', { ns: 'translation' })} {format(order.submittedAt, 'yyyy-MM-dd HH:mm')} {`(${differenceInMinutes(new Date(), new Date(order.submittedAt))} ${t('history-cart.time-minutes-ago', { ns: 'translation' })})`}
                            </p>
                            {!!order.confirmedAt && <div>
                                <p className="mt-1">{t('history.confirmed-at', { ns: 'translation' })} {format(order.confirmedAt, 'yyyy-MM-dd HH:mm')}</p>
                            </div>}

                            {!!order.deliveredAt && <div>
                                <p className="mt-1">{t('history.delivered-at', { ns: 'translation' })} {format(order.deliveredAt, 'yyyy-MM-dd HH:mm')}</p></div>}
                        </div>
                    </div>
                    <div className="pl-3 md:pl-0 w-full md:w-fit md:mt-0 mt-4 flex flex-none items-center gap-x-4">
                        <button
                            type="button"
                            onClick={() => navigate(`/order-history/${order.documentId}`)}
                            className="border-white block rounded-md bg-red-500 px-2.5 py-1.5 text-lg font-semibold text-white shadow-sm"
                        >
                            {t('history.view-order', { ns: 'translation' })}
                        </button>
                        {!isAdminAccess && !order.deliveredAt && !!order.confirmedAt && <a
                            href={order.href}
                            onClick={() => confirmDelivered(order.documentId)}
                            className="border-white block rounded-md bg-red-500 px-2.5 py-1.5 text-lg font-semibold text-white shadow-sm"
                        >
                            {t('history.confirm-order-delivered', { ns: 'translation' })}
                        </a>}
                    </div>
                </li>
            ))}
        </ul>
    )
}