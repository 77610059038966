
import { useTranslation } from 'react-i18next';
import { upperFirst, has } from 'lodash'
import { customAlphabet } from 'nanoid'
import axios from 'axios'
import { useDialogStore, useNotiStore } from '../stores/dialogStore';
import { useAccessStore } from '../stores/accessStore';
import { format } from 'date-fns'
import { shallow } from 'zustand/shallow'
import { get } from 'lodash'

const services = [
    {
        nameEn: 'Contact the Prudence Lounge Client Relations Manager',
        nameSc: '联系诚轩客户关系经理',
        nameTc: '聯繫誠軒客戶關係經理',
    },
]

const CUSTOM_ALPHABET_NANOID = '1234567890'
const NANOID_LENGTH = 6 // total 8, plus 2 digit prefix

export default function Services() {
    const { i18n, t } = useTranslation(['translation']);

    const setServiceDialogContent = useDialogStore((state) => state.setServiceDialogContent)
    const setAction = useDialogStore((state) => state.setAction)
    const setServiceCallSuccessNoti = useNotiStore((state) => state.setServiceCallSuccessNoti)
    const setNotiOpen = useNotiStore((state) => state.setNotiOpen)
    const setDialogOpen = useDialogStore((state) => state.setOpen)

    const callService = (service) => {
        const serviceName = service[`name${upperFirst(i18n.language)}`];
        setServiceDialogContent(serviceName);
        setServiceCallSuccessNoti();
        setAction(submitService.bind(null, service));
        setDialogOpen(true)
    }

    const { selectedRooms, deviceId } = useAccessStore(
        (state) => ({
            selectedRooms: state.selectedRooms,
            deviceId: state.deviceId
        }),
        shallow
    )

    const submitService = async (service) => {
        const nanoid = customAlphabet(CUSTOM_ALPHABET_NANOID, NANOID_LENGTH)
        const data = {
            data: {
                totalItems: 1,
                submittedAt: new Date(),
                confirmedAt: null,
                deliveredAt: null,
                cartSnapshot: JSON.stringify(service),
                orderId: `10${nanoid()}-${format(new Date(), 'yyMMdd')}`,
                room: selectedRooms[0].id,
                isHiddenToUser: false,
                orderType: 'SERVICE'
            }
        };
    
        try {
            const orderDone = await axios.post(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/orders`, data);
            if (orderDone) {
                setDialogOpen(false)
                setNotiOpen(true);
    
                setTimeout(() => {
                    setNotiOpen(false);
                    sendSubscriptionNoti(data, orderDone, 'CLIENT_SUBMIT_ORDER');
                }, 2000);
            }
        } catch (error) {
            console.error('error')
        }
    }

    const sendSubscriptionNoti = async (data, order, type) => {
        const orderId = get(order, 'data.data.id', null)
        const documentId = get(order, 'data.data.documentId', null)

        try {
            await axios.post(`${process.env.REACT_APP_STRAPI_ENDPOINT}/api/push-msg/send`, {
                totalItems: data.data.totalItems,
                room: data.data.room,
                roomName: selectedRooms[0].displayName,
                deviceId,
                type,
                order: orderId,
                orderType: data.data.orderType,
                notiUrl: `/order-history/${documentId}`,
                sentAt: new Date(),
                lang: i18n.language
            });
        } catch (error) {
            console.error('error')
        }
    }

    return (
        <div>
            <div className="bg-gray-600 px-4 py-4">
                <h3 className="ml-2 text-xl font-semibold leading-6 text-white">{t('other.service', { ns: 'translation' })}</h3>
            </div>
            <div className={`${i18n.language === 'en'? 'md:grid-cols-1': 'md:grid-cols-2'} px-6 py-3 grid grid-cols-1 gap-6`}>
                {services.map((service, idx) => (
                    <div
                        key={idx}
                        onClick={() => callService(service)}
                        className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-2 shadow-sm"
                    >
                        <div className="min-w-0 flex-1">
                            <a href="#" className="focus:outline-none">
                                <span aria-hidden="true" className="absolute inset-0" />
                                <p className={`${i18n.language === 'en'? 'text-xl': 'text-xl'} font-medium text-gray-900`}>{service[`name${upperFirst(i18n.language)}`]}</p>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}